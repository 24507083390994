/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";

import { Container, Row, Col } from "react-bootstrap";

const Layout = ({ children, pageInfo }) => (
  <>
    <Container fluid className="px-0 main">
      <main>{children}</main>
    </Container>
    <Container fluid className="px-0">
      <Row className="g-0">
        <Col className="footer-col">
          <footer>
            <span>© {new Date().getFullYear()}</span>
          </footer>
        </Col>
      </Row>
    </Container>
  </>
);

export default Layout;
